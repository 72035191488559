import React from 'react';
import compose from 'recompose/compose';
// Material UI =>
import Grid from '@material-ui/core/Grid';
// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

//Components
import Breakpoint from '../Utilities/Breakpoint';

// Actions 
import {getCategories} from "./Actions";
import { UUID } from '../Utilities/Helpers';

class Categories extends React.Component {


    // Get Categories
    componentDidMount(){
        this.props.getCategories();
    }



    render() {
        var categories = this.props.categories.categories;
        return (
            <div id="categories">
                <Breakpoint>
                    <Grid container justify={"center"}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Grid container justify={"center"}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <h2>CATEGORÍAS</h2>
                                    <p>LOS GANADORES ABSOLUTOS OBTENDRÁN LA INSCRIPCIÓN AL EVENTO MÉXICO SUPER SHOW</p>
                                </Grid>
                                {categories ? 
                                    categories.map((category) => {
                                        return (
                                            <Grid item key={UUID()} md={4} sm={6} xs={6}>
                                                <div className={"item"}><i className="fas fa-caret-right"/>{category.name}</div>
                                            </Grid>
                                        )
                                    })
                                : null}             
                            </Grid>
                        </Grid>
                    </Grid>
                </Breakpoint>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getCategories
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Categories); 