import update from 'immutability-helper';

export const history = (state = {
    history:	false,
}, action) => {
    switch (action.type) {

		case "MERGE-HISTORY-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};