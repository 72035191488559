export function alertModal(open, title, text, type){

	return (dispatch) => {
		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				"alert" : {
					"open" : open,
					"title": title,
					"text" : text,
					"type" : type
				}
			}
        });
	}
}


export function closeSnack() {
	return (dispatch) => {
		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				"snack" : false
			}
        });
	};
}


// Maneja los diálogos del sitio:
export function handleDialog(dialog) {
	return (dispatch, getState) => {
		var state = getState();	
		var status = !state.global[dialog];

		// Remove Scrollbar from body and html tags:
		var body = document.body;
		var html = document.getElementsByTagName('html')[0];

		(status) ? body.classList.add("no-overflow") : body.classList.remove("no-overflow");
		(status) ? html.classList.add("no-overflow") : html.classList.remove("no-overflow");

		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				[dialog] : status
			}
        });
	};
}