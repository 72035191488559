import React from 'react';
import compose from 'recompose/compose';

// Helpers
import {UUID} from '../Utilities/Helpers';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {getBanners} from "./Actions";

// Components
import Waiter from "../Utilities/Waiter";

// Siema Carousel: 
import ReactSiema from 'react-siema'

class Banners extends React.Component {

    componentDidMount(){
        this.props.getBanners();
    }


    // Component should update only if number of banners changes
    shouldComponentUpdate(nextProps){
        if(this.props.banners.banners !== undefined)
        {
            if(this.props.banners.banners.length !== nextProps.banners.banners.length)
            return true;
        }
        return false;
    }    

    render() {
        let slider;
        let banners = this.props.banners.banners;
        if(banners)
        {
            // this.props.global.width
            var options = {
                resizeDebounce: 100,
                duration: 200,
                easing: 'ease-out',
                perPage: 1,
                startIndex: 0,
                draggable: true,
                threshold: 20,
                loop: true,
            }
            return (
                <div id="banners">
                    <ReactSiema {...options} ref={siema => slider = siema}>
                        {banners.map((banner) =>
                            <div key={UUID()} className={"item-holder"}>
                                <div className="item" style={{ "backgroundImage" : `url("${banner}")` }}>
                                </div>
                            </div>
                        )}
                    </ReactSiema>
                    {banners.length > 1 ? 
                        <React.Fragment>
                            <span className={"controller left"} onClick={() => slider.prev()}>
                                <img src={"/img/icon-left.png"} alt={"Previous slider"}/>
                            </span>
                            <span className={"controller right"} onClick={() => slider.next()}>
                            <img src={"/img/icon-right.png"} alt={"Next slider"}/>
                            </span>
                        </React.Fragment>
                    : null}
                </div>
            ) 
        }
        else
        {
            return <div id="banners"><Waiter styles={{ marginTop: "300px" }}/></div>
        }

    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getBanners
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Banners); 