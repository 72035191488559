import update from 'immutability-helper';

export const global = (state = {
    loading:	false,
    API: "https://mrymisshercules.com/api/index.php?action=",
    snack: false,
    submiting: false,
    width: window.innerWidth,
    height: window.innerHeight,
    alert : {
        "open" : false,
        "title": false,
        "text" : false,
        "type" : false
    },
    dialogInscription: false,  // Diálogo de pre-inscripción
}, action) => {
    switch (action.type) {

		case "MERGE-GLOBAL-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};