import {API} from '../Utilities/Helpers';


export function getGallery() {
	return (dispatch, getState) => {
		var state    = getState();
			var request  = API(`${state.global.API}getGallery`, 'GET');
			request.then(res => res.json()).then(json => {
				dispatch({
					type 	: "MERGE-GALLERY-STATES", 
					payload	: {
						images: json
					}
				});
		});
	};
}

export function popUp(img){
	return (dispatch, getState) => {
		var state    = getState();
		dispatch({
			type 	: "MERGE-GALLERY-STATES", 
			payload	: {
				popup: !state.gallery.popup,
				img: img
			}
		});
	};
}