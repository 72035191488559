import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Breakpoint from '../Utilities/Breakpoint';
import Waiter from '../Utilities/Waiter';

// Helpers
import { UUID } from '../Utilities/Helpers';

// Actions
import {getGallery, popUp} from "./Actions";

class Gallery extends React.Component {

    componentDidMount(){
        this.props.getGallery();
    }


    // Component updates if the number of logos changes:
    shouldComponentUpdate(nextProps){
        if(this.props.gallery.images !== undefined)
        {
            if(this.props.gallery.images.length !== nextProps.gallery.images.length)
            return true;
        }
        return false;
    }

    imgLoadRetry(e){
        var img = e.target;
        var src = img.src;
        img.src = src;
    }


    render() {
        var images = this.props.gallery.images;
        return (
            <div id="gallery">
                <Breakpoint>
                    <Grid container justify={"center"}>
                        {images ? 
                            images.map((img, idx) => {
                                return(
                                    <Grid item md={2} sm={4} xs={6} key={UUID()}>
                                        <div onClick={() => this.props.popUp(idx)} className={"img-holder"} style={{ backgroundImage: `url("${img}")` }} />
                                        <img src={img} style={{ display: "none" }} onError={(e) => this.imgLoadRetry(e) } alt={img}/>
                                    </Grid>
                                )
                            })
                        : <Grid item xs={12}><Waiter/></Grid> }
                    </Grid>
                </Breakpoint>
            </div>
        )
    }

}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getGallery,
        popUp
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Gallery); 