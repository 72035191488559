import update from 'immutability-helper';

export const inscription = (state = {
    deviceSessionId: false,
    method: "card",
    amount: '$0.00Mxn',
    terms: false,
    pp: [],
}, action) => {
    switch (action.type) {

		case "MERGE-INSCRIPTION-STATES":
			state = update(state, {$merge: action.payload});
        break;
        
        default:
            return state;
    }

    return state
};