import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Grid } from '@material-ui/core';

// Components
import Waiter from "../Utilities/Waiter";
import Paypal from "./Paypal";

// Actions =>
import {handleDialog} from '../Utilities/Actions';
import {getCategories, checkCategory} from '../Categories/Actions';
import {inscriptionForm, deviceSessionId} from './Actions';
import {getEvent} from "../Layout/Actions";
import { UUID } from '../Utilities/Helpers';


class Inscription extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            "method" : "card",
            "retail" : "",
            "firstname" : "",
            "lastname" : "",
            "email" : "",
            "phone" : "",
            "address" : "",
            "state" : "",
            "city" : "",
            "zip" : "",
            "country" : "",
            "birthday" : "",
            "weight" : "",
            "height" : "",
            "sponsors" : "",
            "terms" : false,
            "firm" : "",
            "card" : {
                name: "",
                number: "",
                year: "",
                month: "",
                type: ""
            }
        };
    }

    fillForm(){
        this.setState({
            "firstname" : "Firstname",
            "lastname" : "Lastname",
            "email" : "test@tester.com",
            "phone" : "0123456789",
            "address" : "Some address #123",
            "state" : "State",
            "city" : "City",
            "zip" : "1234",
            "country" : "México",
            "birthday" : "1986-12-27",
            "weight" : "80",
            "height" : "170",
            "sponsors" : "Nike, Adidas, ONU",
            "terms" : true,
            "firm" : "My Firstname Lastname",
            "card" : {
                "name" : "Firstname Lastname",
                "number" : "1234567890123456",
                "year" : "2019",
                "month" : "12",
                "cvv" : "123"
            }
        });
    }


    componentDidMount(){
        this.props.getCategories();
        this.props.deviceSessionId();
        this.props.getEvent();
        //this.fillForm();
    }



    switchMethod(method){
        this.setState({"method" : method});
    }


    switchRetail(retail){
        this.setState({"retail" : retail});
    }



    render() {
        var categories  = this.props.categories.categories;
        var terms       = this.props.layout.event.terms;
        var card = this.state.card;
        var method = this.state.method;
        var tabCard = (method === "card") ? "tab active" : "tab";
        var tabCash = (method === "cash") ? "tab active" : "tab";
        var retail = this.state.retail;
        var retailOxxo = (retail === 'OXXO') ? 'selected' : '';
        var retailSeven = (retail === 'SEVEN_ELEVEN') ? 'selected' : '';
        var ppftype = "text";
        var pp = this.props.inscription.pp;
        return (
            <Dialog fullWidth={true} maxWidth={false} onClose={() => this.props.handleDialog("dialogInscription") } open={this.props.global.dialogInscription} className={"mui-dialog"} id={"inscription"}>
                <DialogTitle className="header">
                    <span onClick={() => this.props.handleDialog("dialogInscription") }>
                        <img src={"/img/icon-close.png"} alt="Close this dialog" />
                    </span>                        
                </DialogTitle>
                <DialogContent className="content">
                    <form onSubmit={(e) => {e.preventDefault(); this.props.inscriptionForm(e); }} id={"inscriptionForm"}>
                        <Grid container justify={"center"}>
                            <Grid item md={8}>
                                <div className="head">
                                    <h1>PRE-INSCRIPCIÓN Y PAGO</h1>
                                    <hr/>
                                </div>           
                            </Grid>
                        </Grid>
                        <div className="form-holder">
                            <Grid container justify={"center"}>
                                <Grid item md={8}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h2>Formulario de inscripción</h2>
                                            <p>Fecha del evento: 04 de mayo de 2019.</p>
                                            <p>Lugar del evento: Hotel Misión Guadalajara Carlton -  Guadalajara, MX</p>
                                            <p>
                                                *El costo para una sola categoría es de $500 pesos o $25 dolares,si agregan otra categoría $250 pesos o $12.50 dolares cada clase extra. 
                                                *Puedes registrarte para múltiples categorías.  Ultimo día de pre-inscripcion o pre-registro con esas tarifas hasta el 02 de mayo. 
                                                Costo día del evento $750 y categoría extra $500 pesos cada una.
                                            </p>
                                            <p>* Registro no reembolsable e intransferible.</p>

                                            <h2>INFORMACÍON ATLETA</h2>                                        
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={40}>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Nombre</label>
                                            <input type="text" name="name" defaultValue={this.state.firstname}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Apellidos</label>
                                            <input type="text" name="lastname" defaultValue={this.state.lastname}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Correo electrónico</label>
                                            <input type="text" name="email" defaultValue={this.state.email}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Número telefónico</label>
                                            <input type="number" name="phone" defaultValue={this.state.phone}/>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <label>*Dirección</label>
                                            <input type="text" name="address" defaultValue={this.state.address}/>
                                        </Grid>          
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Estado</label>
                                            <input type="text" name="state" defaultValue={this.state.state}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Ciudad</label>
                                            <input type="text" name="city" defaultValue={this.state.city}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Código Postal</label>
                                            <input type="text" name="zip" defaultValue={this.state.zip}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*País</label>
                                            <input type="text" name="country" defaultValue={this.state.country}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Fecha de nacimiento</label>
                                            <input type="date" name="birthday" defaultValue={this.state.birthday}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Peso</label>
                                            <input type="number" name="weight" defaultValue={this.state.weight}/>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Estatura</label>
                                            <input type="number" name="height" defaultValue={this.state.height}/>
                                        </Grid>  
                                        <Grid item md={6} sm={12} xs={12}>
                                            <label>*Coach</label>
                                            <input type="text" name="sponsors" defaultValue={this.state.sponsors}/>
                                        </Grid>    

                                        <Grid item xs={12}>
                                            <h3>*Seleccione las clases para las que se está registrando</h3>
                                        </Grid>                                             
                                        {/* Categories */}
                                        {categories ? 
                                            categories.map((c) => {
                                                return(
                                                    <Grid item md={6} key={UUID()}>
                                                        <section>
                                                            <h2>{c.name}</h2>
                                                            <nav>
                                                                {c.subcategories.map((s) => {
                                                                    return(
                                                                        <label key={UUID()}><input onChange={() => this.props.checkCategory(c.id, s.id) } name="category[]" value={s.id} type="checkbox" defaultChecked={s.checked}/> {s.name} <b/></label>
                                                                    )
                                                                })}
                                                            </nav>
                                                        </section>
                                                    </Grid>
                                                )
                                            })
                                        : <Grid item xs={12}><Waiter/></Grid>} 
                                    </Grid>
                                    {/* Términos y condiciones */}
                                    {terms ?
                                        <small dangerouslySetInnerHTML={ {__html: terms} }></small>
                                    : <Waiter/>}
                                </Grid>
                                <Grid item md={7}>
                                    <section>
                                        <nav>
                                            <label className={"acceptance"}><input type="checkbox" name="terms" defaultChecked={this.state.terms}/> ACEPTO LOS TÉRMINOS Y CONDICIONES DEL EVENTO<b/></label>
                                        </nav>
                                    </section>

                                    <label>*Firma -  Escriba su nombre para la firma electrónica.</label>
                                    <input type="text" name="firm" defaultValue={this.state.firm}/>

                                    {/* PayPal */}
                                    <Paypal/>

                                    <legend>AVISO: si tiene un problema con el procesamiento de su pago, comuníquese con su banco para asegurarse de que no tiene un bloqueo de compra en línea en su cuenta.</legend>
                                </Grid>
                            </Grid>  
                        </div>
                    </form>


                    <form id="paypal" action={pp.action} method="post">  
                        <input type={ppftype} name="cmd" defaultValue="_cart"/>
                        <input type={ppftype} name="business" defaultValue={pp.business}/>
                        
                        <input type={ppftype} name="image_url" defaultValue="https://mrymisshercules.com/img/logo.png"/>  

                        <input type={ppftype} name="no_shipping" defaultValue="1"/>  
                        <input type={ppftype} name="currency_code" defaultValue="MXN"/>
                        <input type={ppftype} name="bn" defaultValue="BuyNow"/>  
                        <input type={ppftype} name="rm" defaultValue="2" />
                        <input type={ppftype} name="no_note" defaultValue="1" />
                        <input type={ppftype} name="lc" defaultValue="ES"/>
                        <input type={ppftype} name="charset" defaultValue="utf-8" />
                        <input type={ppftype} defaultValue="1" name="upload"/>


                        <input type={ppftype} name="item_name_1" defaultValue="Pre-registro en mrymisshercules.com"/>  
                        <input type={ppftype} name="item_number_1" defaultValue={pp.uuid}/>  
                        <input type={ppftype} name="amount_1" defaultValue={pp.amount}/>  

                        <input type={ppftype} name="email" defaultValue={pp.email}/>
                        <input type={ppftype} name="invoice" defaultValue={pp.uuid} />
                        
                        <input type={ppftype} name="return" defaultValue="https://mrymisshercules.com/api/index.php?action=ppNotify"/>
                        <input type={ppftype} name="notify_url" defaultValue="https://mrymisshercules.com/api/index.php?action=ppNotify" />
                        <input type={ppftype} name="cancel_return" defaultValue="https://mrymisshercules.com/api/index.php?action=ppNotify&cancel=true" />
                        <input type={ppftype} name="custom" defaultValue={pp.uuid} />
                        <input type={ppftype} name="paymentaction" defaultValue="sale"/>
                        <input type={ppftype} defaultValue="display" name="1"/>

                        <button type="submit">ADQUIRIR</button>   
                    </form> 



                </DialogContent>
            </Dialog>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        handleDialog,
        getCategories,
        inscriptionForm,
        deviceSessionId,
        checkCategory,
        getEvent
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Inscription); 