import React from 'react';
import compose from 'recompose/compose';
// Material UI =>
import CircularProgress from '@material-ui/core/CircularProgress';
// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
class Waiter extends React.Component {
    render() {
        return (
            <div className="waiter" style={this.props.styles}>
                <CircularProgress className={"spinner"} thickness={1.5}/>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Waiter); 