import React from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';

// Material UI =>
import CircularProgress from '@material-ui/core/CircularProgress';


/****************************************
    Return Render =>
****************************************/
class ButtonWrapper extends React.Component {
    render() {
        return (
            <div className="button-wrapper">
            	{this.props.global.submiting ? (
            		<CircularProgress className="submiting" style={this.props.styles} thickness={1.5}/>
            	) : (
            		<React.Fragment>{this.props.children}</React.Fragment>
            	)}
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


export default compose(
    connect(mapStateToProps)
)(ButtonWrapper); 