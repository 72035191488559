import React from 'react';
import ReactDOM from 'react-dom';

// Service Worker
import registerServiceWorker from './registerServiceWorker';

// Redux =>
import { createStore, applyMiddleware } from 'redux';

// Thunk => 
import thunk from 'redux-thunk';

// Reducers =>
import CombinedReducers from './combine-reducers';

// Provider =>
import {Provider} from 'react-redux';

// Routing =>withRouter
import { BrowserRouter as Router } from 'react-router-dom';

// Css
import './reset.css';
import './styles.css';

// Components
import Layout from './Layout/Layout';

// Actions 
import {watchResize} from "./Utilities/Helpers";

// Redux Store is initialized here =>
const store = createStore(CombinedReducers, applyMiddleware(thunk));

window.addEventListener('resize', () => {
    store.dispatch(watchResize());
});

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Layout/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();