import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Breakpoint from "../Utilities/Breakpoint";

// Helpers
import {windowScroll} from "../Utilities/Helpers";

// Actions 
import {getEvent} from "./Actions";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu: false};
    }


    componentDidMount(){
        this.props.getEvent();
    }

    handleMenuToggle = () => this.setState({menu: !this.state.menu});

    render() {
        var event = this.props.layout.event;
        return (
            <header>
                <Breakpoint>
                    <Grid container>
                        <Grid item md={2} sm={3} xs={3}>
                            <img src={"/img/logo.png"} alt={"Logo"}/>
                        </Grid>
                        <Grid item lg={7} md={8} sm={false} xs={false}>
                            <nav className={"menu"}>
                                <span onClick={() => windowScroll("history")}>Historia</span>
                                <span onClick={() => windowScroll("categories")}>Categorías</span>
                                <span onClick={() => windowScroll("subcategories")}>Pre-inscripción</span>
                                <span onClick={() => windowScroll("sede")}>Sede del Evento</span>
                                <span onClick={() => windowScroll("tickets")}>Boletos</span>
                                <span onClick={() => windowScroll("contact")}>Contacto</span>
                            </nav>
                        </Grid>
                        <Grid item lg={3} md={2} sm={9} xs={9}>
                            <nav className={"social-links"}>
                                <a href={event.instagram} rel="noopener noreferrer" target="_blank">
                                    <img src="/img/icon-instagram.png" alt={"Instagram"} />
                                </a>
                                <a href={event.facebook} rel="noopener noreferrer" target="_blank">
                                    <img src="/img/icon-facebook.png" alt={"Facebook"} />
                                </a>
                                {/*
                                <a href="https://instagram.com" rel="noopener noreferrer" target="_blank">
                                    <img src="/img/icon-profile.png" alt={"Profile"} />
                                </a>
                                <a href="https://instagram.com" rel="noopener noreferrer" target="_blank">
                                    <img src="/img/icon-bag.png" alt={"Bag"} />
                                </a>  
                                */}
                                <span className="mobile-menu" onClick={() => this.handleMenuToggle() }>
                                    <img src="/img/icon-menu.png" alt={"menu"} />
                                </span> 
                            </nav>
                        </Grid>
                    </Grid>
                </Breakpoint>
                {this.state.menu ? 
                    <Drawer className="mui-drawer" anchor="right" open={() => this.state.menu }  onClose={() => this.handleMenuToggle()}>
                        <nav className={"menu"}>
                            <span onClick={() => { windowScroll("history"); this.handleMenuToggle() }}>Historia</span>
                            <span onClick={() => { windowScroll("categories"); this.handleMenuToggle() }}>Categorías</span>
                            <span onClick={() => { windowScroll("subcategories"); this.handleMenuToggle() }}>Pre-inscripción</span>
                            <span onClick={() => { windowScroll("sede"); this.handleMenuToggle() }}>Sede del Evento</span>
                            <span onClick={() => { windowScroll("tickets"); this.handleMenuToggle() }}>Boletos</span>
                            <span onClick={() => { windowScroll("contact"); this.handleMenuToggle() }}>Contacto</span>
                        </nav>
                    </Drawer>
                : null}
            </header>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getEvent
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Header); 