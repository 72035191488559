import {API} from "../Utilities/Helpers";

export function contactForm(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				"submiting" : true
			}
        });

        var state    = getState();
        var formData = new FormData(e.target);
        var request  = API(`${state.global.API}contactForm`, 'POST', formData);
		request.then(res => res.json()).then(errors => {
			if(errors.length > 0)
			{
				dispatch({
					type 	: "MERGE-GLOBAL-STATES", 
					payload	: {
						"snack" : {
                            message: errors[0],
                            type : "error"
                        },
                        "submiting" : false
					}
				});
			}
			else
			{
				document.getElementById("contact").reset();

				dispatch({
					type 	: "MERGE-GLOBAL-STATES", 
					payload	: {
						"snack" : {
                            message: "¡Muchas gracias! Hemos recibido tus comentarios. En breve nos pondremos en contacto.",
                            type : "success"
                        },
                        "submiting" : false
					}
				});
			}
        });
	};
}
