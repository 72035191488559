import React from 'react';
import compose from 'recompose/compose';
// Material UI =>
import Grid from '@material-ui/core/Grid';
// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
class Tickets extends React.Component {
    render() {
        return (
            <div id="tickets">
                <Grid container justify={"center"}>
                    <Grid item md={4}>
                        <h2>BOLETOS</h2>
                        <p>Costo público general $250 pesos.</p>
                        <a href="https://web.superboletos.com/SuperBoletos/JALISCO/DEPORTIVO/MR-Y-MISS-HERCULES/rRitnPRIe4QPgXCsKMen4w" rel="noopener noreferrer" target="_blank" className="btn">COMPRAR BOLETOS</a>
                        <img src={"/img/logo-super-boletos.png"} alt={"Súper Boletos"}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Tickets); 