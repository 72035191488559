import {API} from "../Utilities/Helpers";


// Banners
export function getBanners() {
	return (dispatch, getState) => {
		dispatch({
			type 	: "MERGE-BANNERS-STATES", 
			payload	: {
				banners: false
			}
		});
        var state    = getState();
		var request  = API(`${state.global.API}getBanners`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-BANNERS-STATES", 
				payload	: {
					banners: json
				}
			});
        });
	};
}