import React from 'react';
import compose from 'recompose/compose';


import {connect} from 'react-redux';

/****************************************
    Return Render =>
****************************************/
class Breakpoint extends React.Component {
    render() {
        return (
            <div className="breakpoint">
            	{this.props.children}
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


export default compose(
    connect(mapStateToProps, null)
)(Breakpoint); 