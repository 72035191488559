import React from 'react';
import compose from 'recompose/compose';
// Material UI =>
import Grid from '@material-ui/core/Grid';
// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Breakpoint from '../Utilities/Breakpoint';
import Waiter from '../Utilities/Waiter';


// Actions
import {getHistory} from "./Actions";

class History extends React.Component {


    componentDidMount(){
        this.props.getHistory();
    }

    createMarkup(html) {
        return {__html: html};
    }

    
    render() {
        var history = this.props.history.history;
        return (
            <div id="history">
                <Breakpoint>
                    <Grid container justify={"flex-end"} spacing={40}>
                        <Grid item lg={2} md={12} sm={12} xs={12}>
                            <img src={"/img/roy-bio.png"} alt={"Roy Velasco"}/>
                        </Grid>
                        <Grid item lg={5} md={5} sm={12} xs={12}>
                            <article>
                                {history ?
                                    <React.Fragment>
                                        <h2>HISTORIA</h2>
                                        <b>{history.name}</b>
                                        <div dangerouslySetInnerHTML={this.createMarkup(history.description)}/>
                                    </React.Fragment>
                                : <Waiter styles={{ marginTop:"70px"}}/>}
                            </article>
                        </Grid>
                    </Grid>
                </Breakpoint>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getHistory
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(History); 