import React from 'react';
import compose from 'recompose/compose';

import Wrapper from '../Utilities/Wrapper';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
class Paypal extends React.Component {
    render() {
        var amount     = this.props.inscription.amount;
        return (
            <React.Fragment>
                {/* Pay Button */}
                <Wrapper styles={{ marginTop: "30px", marginBottom: "50px", color : "#e5bd5a" }}>
                    <button type="submit"><i className="fab fa-cc-paypal"></i> PAGAR {amount}</button>
                </Wrapper>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Paypal); 