import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components:
import Breakpoint from "../Utilities/Breakpoint";
import Wrapper from "../Utilities/Wrapper";

// Actions
import {contactForm} from "./Actions";



class Contact extends React.Component {
    render() {
        return (
            <form onSubmit={(e) => {e.preventDefault(); this.props.contactForm(e); }} id="contact">
                <Breakpoint>
                    <Grid container justify={"center"}>
                        <Grid item md={8} sm={12} xs={12}>
                            <Grid container>
                                <Grid item md={12} sm={12} xs={12}>
                                    <h2>CONTACTO</h2>
                                    <b>Aquí es donde lucharemos,</b>
                                    <p>escríbenos y déjanos tu mensaje. Cualquier duda la aclararemos. </p>
                                </Grid>
                            </Grid>
                        
                            <Grid container>
                                <Grid item md={12} sm={12} xs={12}>
                                    <label>NOMBRE</label>
                                    <input type="text" name="name" autoComplete={"off"} />
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <label>CORREO</label>
                                    <input type="text" name="email" autoComplete={"off"} />
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <label>COMENTARIO</label>
                                    <input type="text" name="comments" autoComplete={"off"} />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Wrapper styles={{ color: "#323232" }}>
                                        <button className="btn">ENVIAR</button>
                                    </Wrapper>
                                </Grid>                                                   
                            </Grid>
                        </Grid>
                    </Grid>
                </Breakpoint>
            </form>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        contactForm
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Contact); 