import {combineReducers} from 'redux';

import {global} from './Utilities/Global';
import {layout} from './Layout/Reducer';
import {categories} from './Categories/Reducer';
import {history} from './History/Reducer';
import {banners} from './Banners/Reducer';
import {gallery} from './Gallery/Reducer';
import {inscription} from './Inscription/Reducer';


// Combine Them All! =>
const all = combineReducers({
	global,
	layout,
	categories,
	history,
	banners,
	gallery,
	inscription
});

export default all;