import {API} from "../Utilities/Helpers";




// Categories
export function getCategories() {
	return (dispatch, getState) => {
		dispatch({
			type 	: "MERGE-CATEGORIES-STATES", 
			payload	: {
				categories: false
			}
		});
        var state    = getState();
		var request  = API(`${state.global.API}getCategories`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-CATEGORIES-STATES", 
				payload	: {
					categories: json
				}
			});
        });
	};
}






export function checkCategory(cid, sid){
	return (dispatch, getState) => {
		var cidx;
		var sidx;
		var state = getState();

		state.categories.categories.forEach((cat, key) => {
			cat.subcategories.forEach((sub, k) => {
				if(cat.id === cid && sub.id === sid)
				{
					cidx = key;
					sidx = k;
				}
			});
		});

		var target = state.categories.categories[cidx].subcategories[sidx];
		target.checked = !target.checked;

		// Update nested target:
		dispatch({
			type 	: "MERGE-INSCRIPTION-STATES", 
			payload	: {
				categories: {
					[cidx] : {
						subcategories: {
							[sidx] : target
						}
					}
				}
			}
		});

		dispatch(calculateCost());
	}
}





export function calculateCost(){
	return (dispatch, getState) => {
		var state = getState();
		var categories = state.categories.categories;
		var formData = new FormData();

		categories.forEach((cat) => {
			cat.subcategories.forEach((sub) => {
				if(sub.checked)
				formData.append("categories[]", sub.id);
			});
		});

        var request  = API(`${state.global.API}calculateCost`, 'POST', formData);
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-INSCRIPTION-STATES", 
				payload	: {
					"amount" : json.amount
				}
			});
        });
	}
}