import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components => 
import Header from "./Header";
import Banners from "../Banners/Banners";
import History from "../History/History";
import Categories from "../Categories/Categories";
import Subcategories from "../Categories/Subcategories";
import Sede from "../Sede/Sede";
import Tickets from "../Tickets/Tickets";
import Gallery from "../Gallery/Gallery";
import Contact from "../Contact/Contact";
import Inscription from "../Inscription/Inscription";
import Footer from "./Footer";
import Snack from '../Utilities/Snack';
import Popup from "../Gallery/Popup";


// Actions 
import {paymentMessage, isRegisterOpen} from "./Actions";

/****************************************
    Return Render =>
****************************************/
class Layout extends React.Component {


    componentDidMount(){
        // Check for PayPal signals:
        this.props.paymentMessage();

        // Check if registration is allowed:
        this.props.isRegisterOpen();
    }


    render() {
        return (
            <React.Fragment>
                {/* Header */}
                <Header/>
                {/* Banners */}
                <Banners/>
                {/* History */}
                <History/>   
                {/* Categories */}
                <Categories/>    
                {/* Subcategories */}
                <Subcategories/>  
                {/* Sede */}
                <Sede/>   
                {/* Tickets */}
                <Tickets/>   
                {/* Gallery */}
                <Gallery/>   
                {/* Contact */}
                <Contact/>
                {/* Footer */}
                <Footer/>   
                {/* Snack Bar */}
                {this.props.global.snack !== false ? <Snack/> : null}     
                {/* Inscription Dialog */}
                {this.props.global.dialogInscription ? <Inscription/> : null}

                {/* Gallery Popup */}
                {this.props.gallery.popup ? <Popup/> : null}
            </React.Fragment>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        paymentMessage,
        isRegisterOpen
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Layout);