import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

//Components
import Breakpoint from '../Utilities/Breakpoint';

// Actions
import {getCategories} from './Actions';
import { UUID } from '../Utilities/Helpers';
import {handleDialog} from "../Utilities/Actions";
import Waiter from '../Utilities/Waiter';

class Subcategories extends React.Component {


    componentDidMount(){
        this.props.getCategories();
    }


    registerButton(){
        var isRegisterOpen = this.props.layout.isRegisterOpen;

        if(isRegisterOpen === null)
        {
            return <Waiter/>
        }

        if(isRegisterOpen === true)
        {
            return <React.Fragment>
                <h3>PRE-INSCRIPCIÒN Y PAGO</h3>
                <button className="btn" onClick={() => this.props.handleDialog("dialogInscription")}>PRE-INSCRIPCIÓN ATLETAS</button>                
            </React.Fragment>
        }

        if(isRegisterOpen === false)
        {
            return null;
        }        
    }
    
    render() {
        var categories = this.props.categories.categories;
        return (
            <div id={"subcategories"}>
                <Breakpoint>
                    <Grid container spacing={40}>
                        {categories ?
                            categories.map((c)=>{
                                return (
                                    <Grid item md={6} sm={12} xs={12} key={UUID()}>
                                        <section>
                                            <h2>{c.name}</h2>
                                            <ul>
                                                {c.subcategories.map((s) => {
                                                    return(
                                                        <li key={UUID()}>{s.name}</li>
                                                    )
                                                })}
                                            </ul>
                                            <a href={c.file} target="_blank" rel="noopener noreferrer">VER REQUISITOS</a>
                                        </section>
                                    </Grid>       
                                )
                            })    
                        : null}     
                        <Grid item md={6} sm={12} xs={12}>
                            <section className="programa">
                                <h2>PROGRAMA</h2>
                                <Grid container spacing={24}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <article>
                                            <h4>Viernes 03 de Mayo</h4>
                                            <p><b>Registro atletas mujeres</b> 3 a 5 pm Hotel Misión Guadalajara Carlton</p>
                                        </article>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <article>
                                            <h4>Viernes 03 de Mayo</h4>
                                            <p><b>Registro atletas hombres</b> 5 a 7 pm Hotel Misión Guadalajara Carlton</p>
                                        </article>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <article>
                                            <h4>Sábado 04 de Mayo</h4>
                                            <p> Inicio del evento 12pm Hotel Misión Guadalajara Carlton - Salón Xalistlico.</p>
                                        </article>
                                    </Grid>        

                                    <Grid item xs={12}>
                                        <small>
                                            <b>Nota importante:</b> Todos los atletas deben presentarse al registro para pesaje y entrega de números. ATLETAS EL DÍA DEL EVENTO LLEGAR PINTADOS. 
                                            <br/><br/>
                                            <b style={{fontSize: "18px"}}>FAVOR DE LLEVAR IMPRESO SU COMPROBANTE DE INSCRIPCIÓN</b>
                                        </small>
                                    </Grid>       
                                </Grid>            
                            </section>
                        </Grid>
                    </Grid>
                    <Grid container spacing={40}>
                        <Grid item md={6} sm={12} xs={12}>
                            <h3>RESERVA TU BRONCEADO Y MAQUILLAJE</h3>
                            <Grid container>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <a className="sunlinks" href="mailto:Sunkissedplaya@gmail.com"><i className="far fa-envelope"></i>sunkissedplaya@gmail.com</a>
                                    <a className="sunlinks" href="tel:+52 1 984 213 8367"><i className="fas fa-phone"></i>+52 1 984 213 8367</a>
                                    {/* <a target="_blank" rel="noopener noreferrer" href="https://www.sunkissedplaya.com/mr-y-miss-hercules-guadalajarafbclid=IwAR3ba5mP2d4SBCRaHhirCwamSowfKpghaxAIWY-UrbjBf2py7wCf1Yy7AYo" className="btn">RESERVAR</a> */}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <img src={"/img/logo-sun.png"} className="sun" alt={"Sun"} width={90}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {this.registerButton()}
                        </Grid>                        
                    </Grid>
                </Breakpoint>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getCategories,
        handleDialog
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Subcategories); 