import {API} from "../Utilities/Helpers";

export function inscriptionForm(e) {
	return (dispatch, getState) => {

		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				"submiting" : true
			}
        });

        var state    = getState();
        var formData = new FormData(e.target);
        var request  = API(`${state.global.API}inscriptionForm`, 'POST', formData);
		request.then(res => res.json()).then(errors => {

			if(typeof errors == "object")
			{
				if(errors.length > 0)
				{
					dispatch({
						type 	: "MERGE-GLOBAL-STATES", 
						payload	: {
							"snack" : {
								message: errors[0],
								type : "golden"
							},
							"submiting" : false
						}
					});
				}
				else
				{
					dispatch({
						type 	: "MERGE-GLOBAL-STATES", 
						payload	: {
							"snack" : {
								message: "Tu registro ha sido completado.",
								type : "success"
							},
							"submiting" : false,
							"dialogInscription" : false
						}
					});
				}
			}
			else
			{
				var uuid = errors;
				dispatch(getPP(uuid));
			}
        });
	};
}



// Get PayPal form fields config
export function getPP(uuid){
	return (dispatch, getState) => {
        var state    = getState();
		var request  = API(`${state.global.API}getPP&uuid=${uuid}`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-INSCRIPTION-STATES", 
				payload	: {
					pp: json
				}
			});

			setTimeout(function(){
				document.getElementById('paypal').submit();
			}, 100);
        });
	}
}



export function deviceSessionId(){
	return (dispatch, getState) => {
        var state    = getState();
		var request  = API(`${state.global.API}deviceSessionId`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-INSCRIPTION-STATES", 
				payload	: {
					deviceSessionId: json
				}
			});
        });
	}
}