export function API(url, method, body){
    var token = (localStorage.getItem('token')) ? localStorage.getItem('token') : 'no-token';

    var obj = {
        'method' : method,
        'headers': {
            'Auth-Token' : token
        }
    }
 
    if(method === 'POST')
    obj["body"] = body;

    var request = new Request(url, obj);
    return fetch(request);
}


// Window Scroller
export function windowScroll(id){
    var rect = document.getElementById(id).getBoundingClientRect();
    window.scroll({ top: rect.top, left: rect.left, behavior: 'smooth' });
}


export function UUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}



export function watchResize() {
	return (dispatch) => {
		dispatch({
			type 	: "MERGE-GLOBAL-STATES", 
			payload	: {
				width: window.innerWidth,
				height: window.innerHeight
			}
        });
    };
}