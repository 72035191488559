import update from 'immutability-helper';

export const gallery = (state = {
    images:	false,
    popup: false,
    img: false, //The target image, the image that was clicked
}, action) => {
    switch (action.type) {

		case "MERGE-GALLERY-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};