import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

//Components
import Breakpoint from "../Utilities/Breakpoint";


class Sede extends React.Component {
    render() {
        return (
            <div id={"sede"}>
                <hr/>
                <Breakpoint>
                    <Grid container spacing={40}>
                        <Grid item lg={6} md={6} sm={12} xs={12} align={"right"}>
                            <img src={"/img/logo-mision.png"} alt="Misión Guadalajara"/>
                            <a href="https://www.google.com/maps?ll=20.663509,-103.349927&z=14&t=m&hl=es-MX&gl=US&mapclient=embed&cid=4481157361394489498" target="_blank" rel="noopener noreferrer">
                                <img src={"/img/icon-map.png"} alt={"Ver mapa"}/>
                            </a>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <section>
                                <h2>SEDE DEL EVENTO</h2>
                                <p>Hotel  Misión Guadalajara</p>
                            </section>
                            <section>
                                <h2>SALÓN XALISTLICO</h2>
                                <p>Av. Niños Héroes 125, Moderna, 44190 Guadalajara, Jal.</p>
                            </section>

                            <section>
                                <p>Tarifa especial de hospedaje para atletas foraneos. $1,130 pesos (impuestos y desayuno incluidos, habitación sencilla o doble). Menciona código. MR HÉRCULES</p>
                            </section>                                                

                            <a href="https://www.hotelesmision.com.mx/destinos/jalisco/hotel-mision-guadalajara-carlton/" target="_blank" className="btn" rel="noopener noreferrer">RESERVAR</a>
                        </Grid>
                    </Grid>
                </Breakpoint>
            </div>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Sede); 