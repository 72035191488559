import {API} from "../Utilities/Helpers";


// History
export function getHistory() {
	return (dispatch, getState) => {
		dispatch({
			type 	: "MERGE-HISTORY-STATES", 
			payload	: {
				history: false
			}
		});
        var state    = getState();
		var request  = API(`${state.global.API}getHistory`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-HISTORY-STATES", 
				payload	: {
					history: json
				}
			});
        });
	};
}