import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeSnack} from './Actions';

// Componetns =>

/****************************************
    Return Render =>
****************************************/
class Snack extends React.Component {
    render() {
        var snack = this.props.global.snack;
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                open={true}
                autoHideDuration={3500}
                onClose={() => this.props.closeSnack()}
                message={snack.message}
                className={"mui-snack "+snack.type}
                action={[<Button key="undo" color="secondary" className="close" size="small" onClick={() => this.props.closeSnack()}>CERRAR</Button>]}
            />
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        closeSnack
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Snack); 