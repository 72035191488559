import update from 'immutability-helper';

export const layout = (state = {
    logos:	false,
    event: false,
    isRegisterOpen: null,  // Check if the registration window is still open.
}, action) => {
    switch (action.type) {

		case "MERGE-LAYOUT-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};