import {API} from '../Utilities/Helpers';




export function isRegisterOpen() {
	return (dispatch, getState) => {
        var state    = getState();
		var request  = API(`${state.global.API}isRegisterOpen`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-LAYOUT-STATES", 
				payload	: {
					isRegisterOpen: json
				}
			});
        });
	};
}

export function getLogos() {
	return (dispatch, getState) => {
        var state    = getState();
		var request  = API(`${state.global.API}getLogos`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-LAYOUT-STATES", 
				payload	: {
					logos: json
				}
			});
        });
	};
}



export function getEvent(){
	return (dispatch, getState) => {
        var state    = getState();
		var request  = API(`${state.global.API}getEvent`, 'GET');
		request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "MERGE-LAYOUT-STATES", 
				payload	: {
					event: json
				}
			});
        });
	}
}




export function paymentMessage(){
	return (dispatch) => {
		if(document.URL.search("payment=cancel") !== -1)
		{
			dispatch({
				type 	: "MERGE-GLOBAL-STATES", 
				payload	: {
					"snack" : {
						message: "El pago ha sido cancelado, no se ha realizado ningún cargo.",
						type : "blue"
					}
				}
			});
		}

		if(document.URL.search("payment=success") !== -1)
		{
			dispatch({
				type 	: "MERGE-GLOBAL-STATES", 
				payload	: {
					"snack" : {
						message: "Hemos recibido correctamente tu información de pago. Recibirás un correo con más detalles. Gracias!",
						type : "success"
					}
				}
			});
		}


		if(document.URL.search("payment=error") !== -1)
		{
			dispatch({
				type 	: "MERGE-GLOBAL-STATES", 
				payload	: {
					"snack" : {
						message: "No ha sido posible procesar tu pago, por favor pónte en contacto con nostoros para resolver a la brevedad.",
						type : "error"
					}
				}
			});
		}		

	}
}
