import React from 'react';
import compose from 'recompose/compose';
// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Breakpoint from "../Utilities/Breakpoint";
import Waiter from "../Utilities/Waiter";

// Actions
import {getLogos} from "./Actions";
import { UUID } from '../Utilities/Helpers';


class Footer extends React.Component {

    componentDidMount(){
        this.props.getLogos();
    }


    imgLoadRetry(e){
        var img = e.target;
        //var src = img.src;
        img.src = 'https://mrymisshercules.com/img/logo.png'; //src;
    }


    // Component updates if the number of logos changes:
    shouldComponentUpdate(nextProps){
        if(this.props.layout.logos !== undefined)
        {
            if(this.props.layout.logos.length !== nextProps.layout.logos.length)
            return true;
        }
        return false;
    }

    render() {
        var logos = this.props.layout.logos;
        return (
            <footer>
                <Breakpoint>
                    <Grid container justify={"center"} spacing={24}>
                        {logos ? 
                            logos.map((logo) => {
                                return (
                                    <Grid key={UUID()} item md={2} sm={4} xs={4}>
                                        <div className="logo-holder">
                                            <span/>
                                            <img src={logo} alt={logo} onError={(e) => this.imgLoadRetry(e) }/>
                                        </div>
                                    </Grid>                                    
                                )
                            })
                        : <Waiter/>}
                                                                                                                                          
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <legend>© 2019 by Mr.Hercules. All Rights Reserved.</legend>
                        </Grid>
                    </Grid>
                </Breakpoint>
            </footer>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getLogos
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Footer); 