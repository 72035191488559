import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Waiter from "../Utilities/Waiter";

// Actions =>
import {getGallery, popUp} from './Actions';
import { UUID } from '../Utilities/Helpers';

// Siema Carousel: 
import ReactSiema from 'react-siema'


class Popup extends React.Component {
    componentDidMount(){
        this.props.getGallery();
    }


    // Component should update only if the selected image is different that the one prevously selected:
    shouldComponentUpdate(nextProps){
        if(this.props.gallery.img !== undefined)
        {
            if(this.props.gallery.img !== nextProps.gallery.img)
            return true;
        }
        return false;
    }

    render() {
        var images = this.props.gallery.images;
        // this.props.global.width
        let sld;
        var opts = {
            resizeDebounce: 100,
            duration: 200,
            easing: 'ease-out',
            perPage: 1,
            startIndex: this.props.gallery.img,
            draggable: true,
            threshold: 20,
            loop: true,
        }
        return (
            <Dialog fullWidth={true} maxWidth={false} onClose={() => this.props.popUp() } open={this.props.gallery.popup} className={"mui-dialog"} id="popup">
                <DialogTitle className="header">
                    <span onClick={() => this.props.popUp() }>
                        <img src={"/img/icon-close.png"} alt="Close this dialog" />
                    </span>                        
                </DialogTitle>
                <DialogContent className="content">
                    {images ? 
                        <div>
                            <ReactSiema {...opts} ref={siema => sld = siema}>
                                {images.map((img) =>
                                    <div key={UUID()} className={"item-holder"}>
                                        <div className="img" style={{ backgroundImage: `url(${img})`}} />
                                    </div>
                                )}
                            </ReactSiema>
                            <span className={"controller left"} onClick={() => sld.prev()}>
                                <img src={"/img/icon-left.png"} alt={"Previous slider"}/>
                            </span>
                            <span className={"controller right"} onClick={() => sld.next()}>
                            <img src={"/img/icon-right.png"} alt={"Next slider"}/>
                            </span>
                        </div>
                    : <Waiter/>}
                </DialogContent>
            </Dialog>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getGallery,
        popUp
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Popup); 